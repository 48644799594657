<template>
  <div class="v-stack h-stretch">
    <div class="card light" v-if="item">
      <div class="content h-stretch">
        <form class="gap-3 h-stretch">
          <label class="text-left required">Main Category:</label>
          <select
            v-model="item.categoryMain"
            @change="onMainCategoryChange($event.target.value)"
          >
            <option v-for="category in mainCategories" :key="category">
              {{ category }}
            </option>
          </select>
          <label class="text-left required">Sub Category:</label>
          <select v-model="item.categorySub">
            <option v-for="category in subCategories" :key="category">
              {{ category }}
            </option>
          </select>
          <label class="text-left required">Name:</label>
          <input v-model="item.name" type="text" />
          <label class="text-left required">Unit:</label>
          <select v-model="item.unit">
            <option v-for="unit in units" :key="unit">{{ unit }}</option>
          </select>
          <label class="text-left">Comment:</label>
          <input v-model="item.comment" type="text" />
          <label class="text-left required">Editable Price:</label>
          <input type="checkbox" v-model="item.editablePrice" />
          <label class="text-left required">Editable Count:</label>
          <input type="checkbox" v-model="item.editableCount" />
          <label class="text-left required">Owned:</label>
          <input type="checkbox" v-model="item.inHouse" />
          <div class="h-stack h-end">
            <button @click.prevent="submit()" class="submit">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import constants from "@/constants.js";

export default {
  props: ["id"],
  data() {
    return {
      item: null,
      units: constants.pricelistUnits.map((item) => item.name),
      mainCategories: constants.pricelistMainCategories,
      subCategories: [],
      items: [],
    };
  },
  methods: {
    ...mapActions([
      "updatePricelistItem",
      "getPricelistItems",
      "getPricelistItem",
      "removePricelistItem",
    ]),
    onMainCategoryChange(category) {
      if (category == "TECHNIKA") {
        this.subCategories = constants.pricelistSubCategoriesGear;
      }
      if (category == "PERSONÁL / SLUŽBY") {
        this.subCategories = constants.pricelistSubCategoriesServices;
      }
    },
    submit() {
      this.updatePricelistItem(this.item)
        .then(() => {
          this.$router.go(-1);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getPricelistItem(this.id)
      .then((item) => {
        this.item = item;

        this.onMainCategoryChange(item.categoryMain);
      })
      .catch((error) => {
        console.log(error);
      });

    this.getPricelistItems()
      .then((items) => {
        this.items = items;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>
